export const SWITCH_THEME = 'SWITCH_THEME'
export const IS_LOADING = 'IS_LOADING'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const SELECTED_TAB = 'SELECTED_TAB'
export const SELECTED_CATEGORY = 'SELECTED_CATEGORY'

export const ADD_ITEM_IN_CART = 'ADD_ITEM_IN_CART'
export const ADD_DIRECT_ITEM_IN_CART = 'ADD_DIRECT_ITEM_IN_CART'
export const REMOVE_ITEM_IN_CART = 'REMOVE_ITEM_IN_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const INCREMENT_ITEM = 'INCREMENT_ITEM'
export const DECREMENT_ITEM = 'DECREMENT_ITEM'

export const ADD_EVENT = 'ADD_EVENT'
export const CLEAR_EVENT = 'CLEAR_EVENT'

export const USER_ID = 'USER_ID'
export const STORE_ROUTE = 'STORE_ROUTE'
export const COMMENT_OPEN = 'COMMENT_OPEN'
export const ORGANIC_PRODUCTS = 'ORGANIC_PRODUCTS'
export const RANDER_GAMBA_NETWORKLIST = 'RANDER_GAMBA_NETWORKLIST'
export const ADD_FAV_PRODUCT = 'ADD_FAV_PRODUCT'
export const EDIT_CART = 'EDIT_CART'
export const COMMENTS_COUNT = 'COMMENTS_COUNT'
export const USER_DETAILS = 'USER_DETAILS'

export const POST_MESSAGE = 'POST_MESSAGE'
export const SEARCH_TEXT = 'SEARCH_TEXT'
export const SEARCH_ADDRESS = 'SEARCH_ADDRESS'
export const SEARCH_LAT = 'SEARCH_LAT'
export const SEARCH_LOG = 'SEARCH_LOG'
